/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Portal API
 * API for the EarthOptics platform
 * OpenAPI spec version: v2.0.1 (532900fb)
 */
import { useQuery, useMutation } from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query'
import type { PostCollections201, PostCollectionsBody, V2LabDataUploadCreateBody } from '.././model'
import { djangoAxiosInstance } from '../../django-axios-instance'
import type { ErrorType } from '../../django-axios-instance'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const v2E3b286a06ec74cddCreate = (options?: SecondParameter<typeof djangoAxiosInstance>) => {
  return djangoAxiosInstance<void>({ url: `/v2/e3b286a06ec74cdd/`, method: 'post' }, options)
}

export const getV2E3b286a06ec74cddCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v2E3b286a06ec74cddCreate>>,
    TError,
    TVariables,
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v2E3b286a06ec74cddCreate>>,
  TError,
  TVariables,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v2E3b286a06ec74cddCreate>>,
    TVariables
  > = () => {
    return v2E3b286a06ec74cddCreate(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V2E3b286a06ec74cddCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v2E3b286a06ec74cddCreate>>
>

export type V2E3b286a06ec74cddCreateMutationError = ErrorType<unknown>

export const useV2E3b286a06ec74cddCreate = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v2E3b286a06ec74cddCreate>>,
    TError,
    TVariables,
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV2E3b286a06ec74cddCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Post a supported (SoilStack json file) sampling plan for one or more fields to have those fields and the collections for them created.
 */
export const postCollections = (
  postCollectionsBody: PostCollectionsBody,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  const formData = new FormData()
  if (postCollectionsBody.create_fields !== undefined) {
    formData.append('create_fields', postCollectionsBody.create_fields.toString())
  }
  if (postCollectionsBody.create_collections !== undefined) {
    formData.append('create_collections', postCollectionsBody.create_collections.toString())
  }
  if (postCollectionsBody.org_id !== undefined) {
    formData.append('org_id', postCollectionsBody.org_id)
  }
  if (postCollectionsBody.sampling_plan_file !== undefined) {
    formData.append('sampling_plan_file', postCollectionsBody.sampling_plan_file)
  }
  if (postCollectionsBody.file_type !== undefined) {
    formData.append('file_type', postCollectionsBody.file_type)
  }

  return djangoAxiosInstance<PostCollections201>(
    {
      url: `/v2/file_import/`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  )
}

export const getPostCollectionsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCollections>>,
    TError,
    { data: PostCollectionsBody },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCollections>>,
  TError,
  { data: PostCollectionsBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCollections>>,
    { data: PostCollectionsBody }
  > = (props) => {
    const { data } = props ?? {}

    return postCollections(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostCollectionsMutationResult = NonNullable<Awaited<ReturnType<typeof postCollections>>>
export type PostCollectionsMutationBody = PostCollectionsBody
export type PostCollectionsMutationError = ErrorType<unknown>

export const usePostCollections = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCollections>>,
    TError,
    { data: PostCollectionsBody },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getPostCollectionsMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Subscriber to JobUpdated SNS to rebroadcast this joyful event
Inspired by https://gist.github.com/gaqzi/d3cf5e73afb42e535376
 */
export const v2JobsUpdatedSubscriptionCreate = (
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<void>(
    { url: `/v2/jobs-updated-subscription/`, method: 'post' },
    options
  )
}

export const getV2JobsUpdatedSubscriptionCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v2JobsUpdatedSubscriptionCreate>>,
    TError,
    TVariables,
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v2JobsUpdatedSubscriptionCreate>>,
  TError,
  TVariables,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v2JobsUpdatedSubscriptionCreate>>,
    TVariables
  > = () => {
    return v2JobsUpdatedSubscriptionCreate(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V2JobsUpdatedSubscriptionCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v2JobsUpdatedSubscriptionCreate>>
>

export type V2JobsUpdatedSubscriptionCreateMutationError = ErrorType<unknown>

export const useV2JobsUpdatedSubscriptionCreate = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v2JobsUpdatedSubscriptionCreate>>,
    TError,
    TVariables,
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV2JobsUpdatedSubscriptionCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Upload a Lab Data File and kick off ETL processing
 */
export const v2LabDataUploadCreate = (
  v2LabDataUploadCreateBody: V2LabDataUploadCreateBody,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  const formData = new FormData()
  if (v2LabDataUploadCreateBody.lab_file !== undefined) {
    formData.append('lab_file', v2LabDataUploadCreateBody.lab_file)
  }
  if (v2LabDataUploadCreateBody.lab_id !== undefined) {
    formData.append('lab_id', v2LabDataUploadCreateBody.lab_id)
  }

  return djangoAxiosInstance<void>(
    {
      url: `/v2/lab-data/upload/`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  )
}

export const getV2LabDataUploadCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v2LabDataUploadCreate>>,
    TError,
    { data: V2LabDataUploadCreateBody },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v2LabDataUploadCreate>>,
  TError,
  { data: V2LabDataUploadCreateBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v2LabDataUploadCreate>>,
    { data: V2LabDataUploadCreateBody }
  > = (props) => {
    const { data } = props ?? {}

    return v2LabDataUploadCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V2LabDataUploadCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v2LabDataUploadCreate>>
>
export type V2LabDataUploadCreateMutationBody = V2LabDataUploadCreateBody
export type V2LabDataUploadCreateMutationError = ErrorType<unknown>

export const useV2LabDataUploadCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v2LabDataUploadCreate>>,
    TError,
    { data: V2LabDataUploadCreateBody },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV2LabDataUploadCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * View method that serializes and renders layer JSON. Requires two request params: "job_id" and "layer".
Operated on predictions DB.
 */
export const v2PredictionsRetrieve = (
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<void>({ url: `/v2/predictions/`, method: 'get', signal }, options)
}

export const getV2PredictionsRetrieveQueryKey = () => [`/v2/predictions/`] as const

export const getV2PredictionsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v2PredictionsRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v2PredictionsRetrieve>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof v2PredictionsRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV2PredictionsRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v2PredictionsRetrieve>>> = ({ signal }) =>
    v2PredictionsRetrieve(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type V2PredictionsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v2PredictionsRetrieve>>
>
export type V2PredictionsRetrieveQueryError = ErrorType<unknown>

export const useV2PredictionsRetrieve = <
  TData = Awaited<ReturnType<typeof v2PredictionsRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v2PredictionsRetrieve>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV2PredictionsRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Returns center point of the geo data of all layers. Collects center data from rx_0 layer.
Operates on the predictions DB.
 */
export const v2PredictionsCenterRetrieve = (
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<void>(
    { url: `/v2/predictions/center/`, method: 'get', signal },
    options
  )
}

export const getV2PredictionsCenterRetrieveQueryKey = () => [`/v2/predictions/center/`] as const

export const getV2PredictionsCenterRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v2PredictionsCenterRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v2PredictionsCenterRetrieve>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof v2PredictionsCenterRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV2PredictionsCenterRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v2PredictionsCenterRetrieve>>> = ({
    signal,
  }) => v2PredictionsCenterRetrieve(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type V2PredictionsCenterRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v2PredictionsCenterRetrieve>>
>
export type V2PredictionsCenterRetrieveQueryError = ErrorType<unknown>

export const useV2PredictionsCenterRetrieve = <
  TData = Awaited<ReturnType<typeof v2PredictionsCenterRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v2PredictionsCenterRetrieve>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV2PredictionsCenterRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * View method that serializes and renders layer JSON. Requires two request params: "job_id" and "layer".
Operated on predictions DB.
 */
export const v2PredictionsExportRetrieve = (
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<void>(
    { url: `/v2/predictions/export/`, method: 'get', signal },
    options
  )
}

export const getV2PredictionsExportRetrieveQueryKey = () => [`/v2/predictions/export/`] as const

export const getV2PredictionsExportRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v2PredictionsExportRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v2PredictionsExportRetrieve>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof v2PredictionsExportRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV2PredictionsExportRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v2PredictionsExportRetrieve>>> = ({
    signal,
  }) => v2PredictionsExportRetrieve(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type V2PredictionsExportRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v2PredictionsExportRetrieve>>
>
export type V2PredictionsExportRetrieveQueryError = ErrorType<unknown>

export const useV2PredictionsExportRetrieve = <
  TData = Awaited<ReturnType<typeof v2PredictionsExportRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v2PredictionsExportRetrieve>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV2PredictionsExportRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Returns the list of all layers present in the source data. Operates on the predictions DB.
 */
export const v2PredictionsLayersRetrieve = (
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<void>(
    { url: `/v2/predictions/layers/`, method: 'get', signal },
    options
  )
}

export const getV2PredictionsLayersRetrieveQueryKey = () => [`/v2/predictions/layers/`] as const

export const getV2PredictionsLayersRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v2PredictionsLayersRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v2PredictionsLayersRetrieve>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof v2PredictionsLayersRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV2PredictionsLayersRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v2PredictionsLayersRetrieve>>> = ({
    signal,
  }) => v2PredictionsLayersRetrieve(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type V2PredictionsLayersRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v2PredictionsLayersRetrieve>>
>
export type V2PredictionsLayersRetrieveQueryError = ErrorType<unknown>

export const useV2PredictionsLayersRetrieve = <
  TData = Awaited<ReturnType<typeof v2PredictionsLayersRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v2PredictionsLayersRetrieve>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV2PredictionsLayersRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Returns an object to configure available layers and features that should appear on the frontend.
This object comes from munging the data in the layers list in serialize_layers_list_db.
 */
export const v2PredictionsUiLayersRetrieve = (
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<void>(
    { url: `/v2/predictions/ui_layers/`, method: 'get', signal },
    options
  )
}

export const getV2PredictionsUiLayersRetrieveQueryKey = () =>
  [`/v2/predictions/ui_layers/`] as const

export const getV2PredictionsUiLayersRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v2PredictionsUiLayersRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v2PredictionsUiLayersRetrieve>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof v2PredictionsUiLayersRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV2PredictionsUiLayersRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v2PredictionsUiLayersRetrieve>>> = ({
    signal,
  }) => v2PredictionsUiLayersRetrieve(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type V2PredictionsUiLayersRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v2PredictionsUiLayersRetrieve>>
>
export type V2PredictionsUiLayersRetrieveQueryError = ErrorType<unknown>

export const useV2PredictionsUiLayersRetrieve = <
  TData = Awaited<ReturnType<typeof v2PredictionsUiLayersRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v2PredictionsUiLayersRetrieve>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV2PredictionsUiLayersRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}
