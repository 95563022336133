import { html as compactionDepth } from 'lib/docs/soilmapper/compaction-depth.md'
import { html as compactionOptions } from 'lib/docs/soilmapper/compaction-options.md'
import { html as tillMapperDepth } from 'lib/docs/soilmapper/tillmapper-depth.md'
import { html as tillMapperMaxPsi } from 'lib/docs/soilmapper/tillmapper-max-psi.md'
import { html as tillMapperOptions } from 'lib/docs/soilmapper/tillmapper-options.md'

const P_AND_K =
  'Phosphorus and potassium are two of the three macronutrients (the other being nitrogen) required by plants for optimum growth. They are required in larger amounts compared to micronutrients (e.g., zinc, iron, boron). Soil test values are reported in parts per million (ppm), which represents 1 milligram of extracted nutrient for each kilogram of soil. You can multiply the ppm value by two to estimate pounds per acre to estimate the value in pounds per acre.'

const nutrientDocs = {
  nutrientOptions: 'Show / hide sample locations.',
  phosphorus: P_AND_K,
  potassium: P_AND_K,
  cec: "Cation exchange capacity (CEC) is a soil's total quantity of negative surface charges. It is a measured sum of cations (positively charged ions that are attracted to the negative surface charges in soil). Meq/100g stands for milliequivalents per 100 grams of soil.",
  ph: 'Soil pH is a measure of soil acidity or alkalinity. The pH scale extends from 0 (a very strong acid) to 14 (a very strong alkaline or base), but most soils will have a pH range of 4 to 8.5.',
}

export const soilMapperDocs = {
  ...nutrientDocs,
  compactionDepth,
  compactionOptions,
  tillMapperDepth,
  tillMapperOptions,
  tillMapperMaxPsi,
  bulkDensity: 'Bulk density; dry weight divided by volume (g/cm3)',
  bulkDensityOptions: 'Show / hide Bulk Density sample locations.',
  cMapper:
    'Total soil carbon content displayed as a percentage. Typically between 0.5-6% in agricultural settings. 12-18% for organic soils.',
  cMapperOptions: 'Show / hide Carbon sample locations.',
  compaction:
    'Visualize field compaction in visual color index by depth layer. Index visualize PSI (Pounds per Square Inch) reading. See PSI color index or click on sections of the field for area specific readings of PSI (Pounds per Square Inch).',
  conductivity:
    'EMI (Electromagnetic Induction) Conductivity Data at a frequency of 63030 Hz - Data displayed in mS/m (milliSiemens / meter) which is a unit of conductivity. The higher the value is, the more conductive the soil is. The lower the value is, the less conducive the soil is.',
  condOptions:
    'Conductivity Options are EarthOptics internal functionality to enable additional data exploration. Note that customer facing view only offers conductivity as a frequency of 63030 Hz and the ability to adjust visible range.',
  condVisibleRange:
    'Changing the visibility range scales the conductivity values. This enables colored visualization of more discrete differences in PSI conductivity. For example, if the conductivity ranges from 0 --> 1000 then sliding the upper slider to 90% will change the color of all values above 900 to be red, effectively increasing the visibility of lower values. If you then slide the lower slider to be 10%, it will change all of the values below 100 to be blue which effectively increases the visibility of higher values. This can be used to remove visual outliers.',
  moisture:
    'VWC (Volumetric Water Content) measured at the time of scanning. Displayed as percent moisture content.',
  moistureOptions: 'Show / hide VWC measurement locations.',
  ssurgo:
    'Digital soil data produced and distributed by the Natural Resources Conservation Service - National Cartography and Geospatial Center in the United States. The database has information on soil types and their distribution.',
  tillMapper:
    'Compaction data is used to produce Variable Depth TillMapper. TillMapper enables more precise tillage. Utilize TillMapper customization options (MAX PSI, DEPTH, and OPTIONS) to adjust to use case or preferences.',
}
