export const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return '0B'

  const K = 1024

  const sizes = [
    'B',
    'KB',
    'MB',
    'GB',
    'TB',
  ]

  const i = Math.floor(Math.log(bytes) / Math.log(K))

  // TODO: confirm that this is equivalent of Math.pow(k, i)
  return `${parseFloat((bytes / K ** i).toFixed(2))}${sizes[i]}`
}

export const getFileExtension = (filename: string, includePeriod = true): string => {
  const extension = filename.split('.').pop() as string

  return includePeriod ? `.${extension}` : extension
}

/**
 * Hack to download a file's contents by creating and clicking an <a> element.
 *
 * {@link https://jetrockets.com/blog/l8dadq8oac-how-to-download-files-with-axios JetRockets: How to download files with Axios}
 *
 * @param data We are assuming it's a string. The first use case was a zip, which was returned as a
 * string from the exportRx endpoint.
 * @param filename Name of the output file, including extension.
 */
export function downloadZipFile(data: string, filename: string): void {
  const downloadUrl = window.URL.createObjectURL(new Blob([data], { type: 'application/zip' }))
  const link = document.createElement('a')

  link.href = downloadUrl
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

// CRED: https://stackoverflow.com/a/30800715/1048518
// TODO: consider replacing SoilCollector's "disable Device view btn" approach:
// https://eoproducts.ideas.aha.io/ideas/EOSC-I-63
export function downloadJson(
  data: unknown,
  exportName = 'eo-json-export',
  onDone?: () => void
): void {
  const downloadAnchorNode = document.createElement('a')
  const prettyTime = new Date().toLocaleString()

  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
    JSON.stringify({ time: prettyTime, data })
  )}`

  downloadAnchorNode.setAttribute('href', dataStr)
  downloadAnchorNode.setAttribute('download', `${exportName}.json`)
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()

  if (onDone) {
    onDone()
  }
}
