import { AiOutlineDotChart } from 'react-icons/ai'
import { BsArrowsCollapse, BsNodePlus } from 'react-icons/bs'
import { FaTractor, FaVial } from 'react-icons/fa'
import { GiElectric, GiGroundSprout } from 'react-icons/gi'
import { IoWaterOutline } from 'react-icons/io5'
import { TbLetterC, TbLetterK, TbLetterP } from 'react-icons/tb'
import { soilMapperDocs as docs } from 'lib/docs/soilmapper/docs.soilmapper'

import {
  CONDUCTIVITY_COLOR_STOPS,
  INCHES_COLOR_STOPS,
  INCHES_LEGEND_MARKS,
  MOISTURE_COLOR_STOPS,
  PLASMA_COLOR_STOPS,
} from 'components/tillmapper/config'
import type { AccordionPanelConfig, TillMapLayerSourceId } from 'components/tillmapper/types'

const DCP_LAYER_ID = 'points.dcp'
const VWC_LAYER_ID = 'points.vwc'

const rx: AccordionPanelConfig = {
  colorStops: INCHES_COLOR_STOPS,
  icon: <FaTractor />,
  legendTitle: 'Inches',
  marks: INCHES_LEGEND_MARKS,
  panelDocsHtml: docs.tillMapper,
  sourceId: 'rx',
  title: 'Tillage Calculator',
}

const compaction: AccordionPanelConfig = {
  colorStops: INCHES_COLOR_STOPS,
  icon: <BsArrowsCollapse />,
  legendTitle: 'psi',
  marks: [
    400,
    300,
    200,
    100,
    0,
  ],
  panelDocsHtml: docs.compaction,
  pointsLayerId: DCP_LAYER_ID,
  sourceId: 'compaction',
  title: 'Compaction',
}

const carbon: AccordionPanelConfig = {
  colorStops: PLASMA_COLOR_STOPS,
  icon: <TbLetterC />,
  legendTitle: 'Percent',
  optionsSubPanelDocsHtml: docs.cMapperOptions,
  panelDocsHtml: docs.cMapper,
  pointsLayerId: 'points.carbon',
  sourceId: 'carbon',
  title: 'Total Carbon Concentration',
}

const bulkDensity: AccordionPanelConfig = {
  colorStops: PLASMA_COLOR_STOPS,
  icon: <AiOutlineDotChart />,
  optionsSubPanelDocsHtml: docs.bulkDensityOptions,
  panelDocsHtml: docs.bulkDensity,
  pointsLayerId: 'points.bulkDensity',
  sourceId: 'bulkDensity',
  title: 'Bulk Density',
  legendTitle: (
    <span>
      g/cm<sup>3</sup>
    </span>
  ),
}

const nutrient_cec: AccordionPanelConfig = {
  colorStops: PLASMA_COLOR_STOPS,
  icon: <BsNodePlus />,
  optionsSubPanelDocsHtml: docs.nutrientOptions,
  panelDocsHtml: docs.cec,
  pointsLayerId: 'points.nutrient_cec',
  sourceId: 'nutrient_cec',
  title: 'CEC',
  legendTitle: <span>meq/100g</span>,
}

const nutrient_ppm_k: AccordionPanelConfig = {
  colorStops: PLASMA_COLOR_STOPS,
  icon: <TbLetterK />,
  optionsSubPanelDocsHtml: docs.nutrientOptions,
  panelDocsHtml: docs.potassium,
  pointsLayerId: 'points.nutrient_ppm_k',
  sourceId: 'nutrient_ppm_k',
  title: 'Potassium',
  legendTitle: <span>ppm</span>,
}

const nutrient_ppm_p: AccordionPanelConfig = {
  colorStops: PLASMA_COLOR_STOPS,
  icon: <TbLetterP />,
  optionsSubPanelDocsHtml: docs.nutrientOptions,
  panelDocsHtml: docs.phosphorus,
  pointsLayerId: 'points.nutrient_ppm_p',
  sourceId: 'nutrient_ppm_p',
  title: 'Phosphorus',
  legendTitle: <span>ppm</span>,
}

const nutrient_soilph: AccordionPanelConfig = {
  colorStops: PLASMA_COLOR_STOPS,
  icon: <FaVial />,
  optionsSubPanelDocsHtml: docs.nutrientOptions,
  panelDocsHtml: docs.ph,
  pointsLayerId: 'points.nutrient_soilph',
  sourceId: 'nutrient_soilph',
  title: 'pH',
  legendTitle: <span>pH</span>,
}

const moisture: AccordionPanelConfig = {
  colorStops: MOISTURE_COLOR_STOPS,
  icon: <IoWaterOutline />,
  legendTitle: 'Percent',
  panelDocsHtml: docs.moisture,
  optionsSubPanelDocsHtml: docs.moistureOptions,
  pointsLayerId: VWC_LAYER_ID,
  sourceId: 'moisture',
  title: 'Moisture',
}

const conductivity: AccordionPanelConfig = {
  colorStops: CONDUCTIVITY_COLOR_STOPS,
  icon: <GiElectric />,
  legendTitle: 'mS/m',
  panelDocsHtml: docs.conductivity,
  sourceId: 'conductivity',
  title: 'Conductivity',
}

const ssurgo: AccordionPanelConfig = {
  icon: <GiGroundSprout />,
  legendTitle: 'Hmm',
  omitLegend: true, // already plenty going on in Accordion Panel
  panelDocsHtml: docs.ssurgo,
  sourceId: 'ssurgo', // hmm
  title: 'SSURGO',
}

export const legendConfig: {
  [key in TillMapLayerSourceId]: AccordionPanelConfig
} = {
  rx,
  compaction,
  carbon,
  bulkDensity,
  moisture,
  conductivity,
  ssurgo,
  nutrient_cec,
  nutrient_ppm_k,
  nutrient_ppm_p,
  nutrient_soilph,
}
