/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Portal API
 * API for the EarthOptics platform
 * OpenAPI spec version: v2.0.1 (532900fb)
 */

export type CollectionFilesFileTypeEnum =
  (typeof CollectionFilesFileTypeEnum)[keyof typeof CollectionFilesFileTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollectionFilesFileTypeEnum = {
  COLLECTION_LOG_FILE: 'COLLECTION_LOG_FILE',
  DCP_FILE: 'DCP_FILE',
  DEVICE_INFO_FILE: 'DEVICE_INFO_FILE',
  EMI_FILE: 'EMI_FILE',
  GPR_FILE: 'GPR_FILE',
  IMAGE: 'IMAGE',
  LAB_DATA_FILE: 'LAB_DATA_FILE',
  MANIFEST: 'MANIFEST',
  OTHER: 'OTHER',
  SAMPLING_PLAN: 'SAMPLING_PLAN',
  EO_GEOJSON_V1: 'EO_GEOJSON_V1',
  SOIL_STACK_FILE: 'SOIL_STACK_FILE',
  UNKNOWN: 'UNKNOWN',
} as const
