import { BiLinkExternal } from 'react-icons/bi'
import { Link as RouterLink } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import { useLayerCapabilities } from 'lib/queries'
import { useFieldFeature } from 'lib/queries.fields'
import { useIsStaffUser } from 'lib/queries.user'

import { ReactQueryStates } from 'components/reusable/alerts-and-messages/ReactQueryStates'
import { getSoilMapperV1statusesConfig } from 'components/tillmapper/accordion/tabs/utils.products'
import type { AccordionPanelConfig } from 'components/tillmapper/types'

export type Props = {
  batchId: string
  btnText?: string
  btnVariant?: 'contained' | 'outlined' | 'text'
  fieldId?: string
  jobId?: string
  omitEndIcon?: boolean
  openInNewTab?: boolean
}

type PreppedConfigItem = AccordionPanelConfig & {
  href: string
}

type StatusItemProps = PreppedConfigItem & {
  isAvailable: boolean
  openInNewTab?: boolean
}

const statusItemSx = {
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  px: 0,
  svg: {
    flexShrink: 0,
  },
}

function StatusItem(props: StatusItemProps) {
  const { href, title, icon, isAvailable, openInNewTab } = props

  let Primary = null

  if (isAvailable) {
    if (openInNewTab) {
      Primary = (
        <Link href={href} sx={statusItemSx} target="_blank">
          {title}
          <BiLinkExternal />
        </Link>
      )
    } else {
      Primary = (
        <Link component={RouterLink} sx={statusItemSx} to={href}>
          {title}
        </Link>
      )
    }
  } else {
    Primary = (
      <Typography color="text.disabled" variant="body2">
        {title}
      </Typography>
    )
  }

  return (
    <ListItem sx={{ minWidth: 42, px: 0 }}>
      <ListItemAvatar sx={{ minWidth: 42 }}>
        <Avatar
          sx={{
            // TODO: use theme instead of CSS vars
            bgcolor: isAvailable ? 'var(--c-pri-main)' : 'var(--c-error-main)',
            width: 32,
            height: 32,
            color: '#fff',
          }}
        >
          {icon}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={Primary} />
    </ListItem>
  )
}

export function SoilMapperLayerStatuses(props: Props) {
  const { jobId, fieldId, batchId, openInNewTab } = props
  const isStaff = useIsStaffUser()
  const layerCapsQuery = useLayerCapabilities(isStaff, jobId)
  const { isInitialLoading, error, data } = layerCapsQuery
  const fieldQuery = useFieldFeature(fieldId)
  const statuses = getSoilMapperV1statusesConfig(batchId, fieldId, data)

  if (isInitialLoading || error) return <ReactQueryStates {...layerCapsQuery} />

  if (fieldQuery.isInitialLoading || fieldQuery.error) {
    return <ReactQueryStates {...fieldQuery} />
  }

  if (!data) return <div>No data found</div>
  if (!fieldQuery.data) return <div>No Field data found</div>

  return (
    <List
      dense
      data-cy="soilmapper-links-list"
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: 2,
      }}
    >
      {statuses.map((item) => (
        <StatusItem key={item.sourceId} {...item} openInNewTab={openInNewTab} />
      ))}
    </List>
  )
}
