/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Portal API
 * API for the EarthOptics platform
 * OpenAPI spec version: v2.0.1 (532900fb)
 */

export type GroupEnum = (typeof GroupEnum)[keyof typeof GroupEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GroupEnum = {
  org_admin: 'org_admin',
  qa_specialist: 'qa_specialist',
  collection_manager: 'collection_manager',
  dispatcher: 'dispatcher',
  field_manager: 'field_manager',
  field_tech: 'field_tech',
  field_tech_contact: 'field_tech_contact',
  superuser: 'superuser',
  staff_user: 'staff_user',
} as const
