import type { LineLayer, LinePaint } from 'mapbox-gl'

import { legendConfig } from 'components/tillmapper/accordion/config.legend'

export const existingBoundarySrcId = 'existing-boundary'

export const existingBoundaryOutline: Omit<LineLayer, 'paint'> & { paint: LinePaint } = {
  id: 'existing-boundary-outline',
  type: 'line',
  source: existingBoundarySrcId,
  paint: {
    'line-color': '#a204a2', // MB static image API doesn't like HSL
    'line-width': 3,
  },
}

const DEFAULT_OPACITY = 0.85

export const DEFAULT_OPACITIES = {
  bulkDensity: DEFAULT_OPACITY,
  carbon: DEFAULT_OPACITY,
  compaction: DEFAULT_OPACITY,
  conductivity: DEFAULT_OPACITY,
  moisture: DEFAULT_OPACITY,
  nutrient_cec: DEFAULT_OPACITY,
  nutrient_ppm_k: DEFAULT_OPACITY,
  nutrient_ppm_p: DEFAULT_OPACITY,
  nutrient_soilph: DEFAULT_OPACITY,
  rx: DEFAULT_OPACITY,
  ssurgo: 1, // usually better, and obscures Field points "under" ssurgo layer
}

export const soilMapperLayerIds = {
  bulkDensity: 'bulkDensity',
  carbon: 'carbon',
  compaction: 'compaction',
  conductivity: 'conductivity',
  moisture: 'moisture',
  rx: 'rx',
  ssurgo: 'ssurgo',
}

/** The main "cell" layers, aka not third-party stuff, and not points layers. */
export const interactiveCellLayerIds = [
  legendConfig.bulkDensity.sourceId,
  legendConfig.carbon.sourceId,
  legendConfig.compaction.sourceId,
  legendConfig.conductivity.sourceId,
  legendConfig.moisture.sourceId,
  legendConfig.rx.sourceId,
  legendConfig.nutrient_cec.sourceId,
  legendConfig.nutrient_ppm_k.sourceId,
  legendConfig.nutrient_ppm_p.sourceId,
  legendConfig.nutrient_soilph.sourceId,
]

export const interactiveSamplePointLayerIds = [
  legendConfig.bulkDensity.pointsLayerId,
  legendConfig.carbon.pointsLayerId,
  legendConfig.moisture.pointsLayerId,
  legendConfig.nutrient_cec.pointsLayerId,
  legendConfig.nutrient_ppm_k.pointsLayerId,
  legendConfig.nutrient_ppm_p.pointsLayerId,
  legendConfig.nutrient_soilph.pointsLayerId,
  legendConfig.compaction.pointsLayerId, // can't fight the DCP clicks if we want a new popup
] as string[]
